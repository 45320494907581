<template>
  <footer>
    <div class="foot-nav-container pc_padding">
      <div class="footer-about">
        <div class="footer-item-title">
          About us
        </div>
        <div class="footer-item-content">
          Welcome to Travel Today: your finest source for 4k picture. Our collection is vast,
          including breathtaking sceneries, vibrant cityscapes, abstract art, and serene nature
          scenes. We let you easily browse and download ultra HD images as your desktop or mobile
          background. We refresh your digital experiences with premium pictures thereby
          prettifying your screen’s appearance. And transform them today.
        </div>
      </div>
      <div class="footer-side-row">
        <div class="footer-side-resource">
          <div class="footer-item-title">
            Resources
          </div>
          <div class="footer-item-content resource">
            <div class="footer-item-content-item cursor" @click="goto('terms')">
              Terms of services
            </div>
            <div class="footer-item-content-item cursor" @click="goto('privacy')">
              Privacy policy
            </div>
            <div class="footer-item-content-item cursor" @click="goto('disclaimer')">
              Disclaimer
            </div>
          </div>
        </div>
        <div class="footer-side-contact">
          <div class="footer-item-title">
            Contact us
          </div>
          <div class="footer-item-content">
            <a class="footer-item-content-item" href="mailto:traveltoday.support@gmail.com">traveltoday.support@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
    <div class="corner-module pc_padding">
      Copyright © 2024 TravelToday. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FootModule1',
  computed: {
    ...mapGetters({
      host: 'host',
      deviceType: 'deviceType',
    }),
  },
  methods: {
    goto (where) {
      console.log(this.$route.path);
      this.$router.push({ name: where });
    },
  },
};
</script>
